<template>
  <div class="setting">
    <Nav navType="fun_text" textCon="账户安全">
      <template v-slot:rightText>
        <span class="iconfont icongengduo" style="color:#000;font-size:24px"></span>
      </template>
    </Nav>
    <img class="head_img" src="https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1145670716,4249636328&fm=26&gp=0.jpg" alt="">
    <div class="content">
      <div class="item" @click="gotoUserSafety(2)">
        <div class="label">
          <div class="title">登陆密码</div>
          <div class="explain">建议您定期更改登录密码一保护账户安全</div>
        </div>
        <div class="value cf80">保护中</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="gotoUserSafety(1)">
        <div class="label">
          <div class="title">手机号码</div>
          <div class="explain">若手机号码更换，请尽快更改</div>
        </div>
        <div class="value cf80">153****0900</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="gotoUserSafety(3)">
        <div class="label">
          <div class="title">密保问题</div>
          <div class="explain">方便以后手机号码更换等验证</div>
        </div>
        <div class="value">暂未设置</div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
      <div class="item" @click="gotoLogout">
        <div class="label">
          <div class="title">注销账户</div>
          <div class="explain">注销后无法恢复，请谨慎操作</div>
        </div>
        <div class="value"></div>
        <div class="arrow">
          <div class="iconfont iconqianjin" style="color:#999;font-size:13px"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import Setting from "@/api/setting"
export default {
  data() {
    return {
    };
  },
  mounted(){
    console.log('mounted')
  },
  methods:{
    //获取用户是否已设置密保问题
    async getSelections() {
      console.log('getselections')
      try {
        const res = await Setting.getQuestionSelections("");
        if (res.code == 200) {
          console.log(res.data)
        } else{
          console.log(res);
        }
      } catch(err) {
        console.log(err);
      }
    },
    gotoLogout(){
      this.$router.push('/logout')
    },
    gotoUserSafety(i){
      console.log(i)
      var type = i == 1? "phoneNumber": i == 2? "password":"question"
      sessionStorage.setItem('verifyType', type);
      this.$router.push('/userSafety')
    }
  },
  components: {
    Nav
  }
};
</script>
<style lang="scss" scoped>
.setting {
  width: 100%;
  height: auto;
  padding-top: 45px;
  background-color: #f4f4f4;
  font-family:PingFang SC;
  padding-bottom: 1px;
  min-height: 100vh;
  .head_img {
    width:100%;
    height: 120px;
  }
  .content{
    width:100%;
    padding:0 12px;
    background-color: #fff;
    margin-bottom: 8px;
    .item{
      display: flex;
      height: 62px;
      border-top: 1px solid #f4f4f4;
      .label{
        .title{
          color:#333;
          font-size: 14px;
          margin-top: 11px;
          font-weight:500;
        }
        .explain{
          font-size: 12px;
          color: #999;
          margin-top: 4px; 
        }
      }
      .value{
        line-height: 62px;
        color:#999;
        font-size: 13px;
        text-align: right;
        flex: 1;
      }
      .cf80{
        color:#ff8000;
      }
      .arrow{
        margin-left: 4px;
        line-height: 62px;
        width:7px;
      }
    }
    .item:first-child{
      border:none;
    }
  }
}
</style>
